import { Link } from "gatsby"
import React from "react"

import time from "../../content/assets/time.png"

const box = {
  border: "2px solid red",
  padding: "10px",
  borderRadius: "3px",
}
const bordercolor = {
  borderColor: "red",
}
const greenandbordercolor = {
  color: "#55ab68",
  borderColor: "red",
}
const redandbordercolor = {
  color: "#dc7061",
  borderColor: "red",
}
const tableimg = {
  maxHeight: "50px",
}

const XmasTimes = props => (
  <div className="times text-left mt-2 pt-2">
    <table className="table" style={box}>
      <thead>
        <tr>
          <th scope="col" style={bordercolor}>
            <img alt="Christmas Opening Times" src={time} style={tableimg} />{" "}
            {props.title && props.title}
          </th>
          <th scope="col" style={greenandbordercolor}>
            Opens
          </th>
          <th scope="col" style={redandbordercolor}>
            Closes
          </th>
        </tr>
      </thead>
      <tbody>
        <tr scope="row">
          <th colSpan="3">December</th>
        </tr>
        <tr>
          <td scope="row">Monday 23rd</td>
          <td colSpan="2">
            <span>Closed</span>
          </td>
        </tr>
        <tr>
          <td scope="row">Tuesday 24th (Christmas Eve)</td>
          <td colSpan="2">
            <span>Closed</span>
          </td>
        </tr>
        <tr>
          <td scope="row">Wednesday 25th (Christmas Day)</td>
          <td colSpan="2">
            <span>Closed</span>
          </td>
        </tr>
        <tr>
          <td scope="row">Thursday 26th (Boxing Day)</td>
          <td colSpan="2">
            <span>Closed</span>
          </td>
        </tr>
        <tr>
          <td scope="row">Friday 27th</td>
          <td>10:00</td>
          <td>17:00</td>
        </tr>
        <tr>
          <td scope="row">Saturday 28th</td>
          <td>09:00</td>
          <td>17:00</td>
        </tr>
        <tr>
          <td scope="row">Sunday 29th</td>
          <td>09:00</td>
          <td>17:00</td>
        </tr>
        <tr>
          <td scope="row">Monday 30th</td>
          <td>10:00</td>
          <td>17:00</td>
        </tr>
        <tr>
          <td scope="row">Tuesday 31st (New Year's Eve)</td>
          <td colSpan="2">
            <span>Closed</span>
          </td>
        </tr>
        <tr scope="row">
          <th colSpan="3">January</th>
        </tr>
        <tr>
          <td scope="row">Wednesday 1st (New Year's Day)</td>
          <td colSpan="2">
            <span>Closed</span>
          </td>
        </tr>
        <tr>
          <td scope="row">Thursday 2nd</td>
          <td colSpan="2">
            <span>Open as usual</span>
          </td>
        </tr>
        <tr>
          <td colSpan="3" className="text-center">
            <Link to="/opening-times/" className="p-3 btn btn-primary">
              Back to Opening Times
            </Link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
)

export default XmasTimes
